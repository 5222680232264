import React, { lazy , Suspense, useRef } from 'react'
import { connect } from 'react-redux'
import { NavLink , Route , Switch } from 'react-router-dom'
import { Spin, ConfigProvider } from 'antd'
import zh_CN from 'antd/lib/locale/zh_CN'
import Appcss from './App.module.css'
import 'antd/dist/antd.css'
//组件懒加载
const Login = lazy(()=>import('./components/Login/Login'))
const Admin = lazy(()=>import('./components/Admin/Admin'))
const Error = lazy(()=>import('./components/Error/Error'))
const Refresh = lazy(()=>import('./components/Error/Refresh'))

function App(props) {

  const { state } = props
  const { isPass,error,refresh } = state

  const Nav_Login = useRef()
  const Nav_Admin = useRef()
  const Nav_Error = useRef()
  const Nav_Refresh = useRef()

  React.useEffect(() => {
    // 监听Redux中的状态 
    if (error) {
      Nav_Error.current.click()
    } else if (refresh) {
      Nav_Refresh.current.click()
    }else if (isPass) {
      Nav_Admin.current.click()
    } else {
      Nav_Login.current.click()
    }
  }, [isPass, error,refresh])

  const DOM = React.useMemo(() => {
    return (
        <ConfigProvider componentSize="small" direction="ltr" locale={zh_CN}>
           <NavLink ref={Nav_Login} to={{ pathname: '/login' }} />
           <NavLink ref={Nav_Admin} to={{ pathname: '/admin' }} />
           <NavLink ref={Nav_Error} to={{ pathname: '/error' }} />
           <NavLink ref={Nav_Refresh} to={{ pathname: '/refresh' }} />
          <Suspense fallback={<div className={Appcss.cotain1}><Spin size="large" /></div>}>
              <Switch>
                  <Route path='/login' component={Login} />
                  <Route path='/admin' component={Admin} />
                  <Route path='/error' component={Error} />
                  <Route path='/refresh' component={Refresh} />
                  {/* <Redirect to='/login'/> */}
              </Switch>
          </Suspense>
        </ConfigProvider>
    )
  }, [])

  return DOM
}

export default connect(
  (state) => ({ state: state }),
)(React.memo(App))